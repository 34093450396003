.waiver-content {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: revert;
    line-height: revert;
    font-size: revert;
  }

  pre {
    font-family: revert;
  }

  p {
    line-height: 1.4;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }

  ol,
  ul {
    padding-left: var(--sui-spacing-2);
  }

  ul {
    list-style-type: revert;
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;

        ol {
          list-style-type: lower-alpha;
        }
      }
    }
  }
}

.WaiverFile--download-link:hover {
  color: var(--sui-colors-action-text-hover) !important;
}
