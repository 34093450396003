.u-bg-yellow-1 {
    background-color: #FEED90 !important;
}

.u-border-yellow-1 {
    border-color: #FEED90 !important;
}

.u-text-yellow-2 {
    color: #655601 !important;
}