// Copied breakpoint from config.scss in @teamsnap/teamsnap-ui
$breakpoint-xs: 480px;

.ManageHouseholdMembers {
  .Modal-header {
    padding-bottom: 0px !important;
    padding: 0 !important;

    @media (min-width: $breakpoint-xs) {
      padding-bottom: 1em;
    }
  }

  div.ActionContainer-body {
    @media (min-width: $breakpoint-xs) {
      height: calc(100% - 6vh - 6rem);
    }
  }

  div.ActionContainer-content {
    padding-top: 0px !important;

    @media (min-width: $breakpoint-xs) {
      padding-top: 24px;
    }
  }

  .Tooltip:after {
    min-width: 330px !important;
    top: auto;

    @media (min-width: $breakpoint-xs) {
      min-width: 470px !important;
    }
  }

  .RemoveMember {
    .Modal.Modal--open {
      display: flex !important;
    }

    .Modal-content {
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      margin: auto !important;
      height: max-content !important;
      width: min-content !important;
      border-radius: 5px;
      align-items: center;
    }

    .text-container {
      margin-top: 0.5rem;
      font-size: 1.2em;
      line-height: 2.2em;
    }

    .action-item-container {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
}
