.ConfirmationModal {
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1052;
  background-color: #fff;
  border-radius: 5px;
  height: auto !important;
}

.ConfirmationModal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(29, 43, 53, 0.6);
  top: 0;
  left: 0;
  z-index: 1051;
}
